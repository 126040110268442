import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"
import ProjectLink from "./projectLink"

const List = styled(motion.ul)`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.5rem;
`

const variants = {
  visible: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
}

const Portfolio = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
          edges {
            node {
              frontmatter {
                date
                path
                title
                category
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 550) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="container" id="portfolio">
        <h1>My Work</h1>
        <List
          className="projects"
          variants={variants}
          initial="hidden"
          animate="visible"
        >
          {data.allMarkdownRemark.edges.map((project, index) => (
            <ProjectLink project={project} key={index} delay={index} />
          ))}
        </List>
      </div>
    )}
  />
)

export default Portfolio
