import React from "react"
import { FaLinkedin, FaGithub } from "react-icons/fa"
import styled from "styled-components"
import { motion } from "framer-motion"

const AboutSection = styled.div`
  position: relative;
  overflow: hidden;
  background-color: transparent;
  padding-bottom: 8rem;
  h1,
  p {
    color: var(--white);
  }
  p {
    font-weight: 300;
  }
  svg {
    margin-right: 0.5rem;
    height: 1rem;
    fill: var(--main-accent-color);
  }
`

const HeaderBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--black);
  transform: skewY(-6deg);
  transform-origin: top left;
`

const about = () => {
  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <HeaderBg />
      <AboutSection className="smaller-container">
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          About Me
        </motion.h1>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          <p>
            Born and raised in Lisbon{" "}
            <span role="img" aria-label="Portugal">
              🇵🇹
            </span>
            , I started my career in Marketing, working for companies such as
            Vodafone, Warner, Dreamworks and Yellow Pages. As time progressed, I
            developed an interest in Digital Marketing, in fields such as SEO,
            Analytics, Social Media and Content Creation. I worked abroad
            (Switzerland) as a Digital Marketing Director for a couple of years
            and then decided to return to Lisbon. I now teach all these topics
            to 4 classes every year, in the same Marketing university I got my
            degree.
          </p>
          <p>
            I always considered myself pretty tech-savvy, and throughout the
            years I worked on my HTML and CSS skills. In the Summer of 2015 I
            packed my bags and went to Porto for an 8-week-long coding bootcamp
            to turn my life around. I then worked for agencies and I am now a
            freelance frontend developer.
          </p>
          <p style={{ marginTop: "2rem" }}>
            <FaLinkedin />
            <a
              href="https://www.linkedin.com/in/brunobrito"
              target="_blank"
              rel="noopener noreferrer"
            >
              View my profile
            </a>
          </p>
          <p>
            <FaGithub />
            <a
              href="http://github.com/brunofbrito"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read my code
            </a>
          </p>
        </motion.div>
      </AboutSection>
    </div>
  )
}

export default about
