import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "../components/about"
import Portfolio from "../components/portfolio"

const IndexPage = () => (
  <Layout>
    <SEO
      title="All Projects"
      keywords={[`Bruno Brito`, `portfolio`, `projects`]}
    />
    <About />
    <Portfolio />
  </Layout>
)

export default IndexPage
