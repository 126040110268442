import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { motion } from "framer-motion"

const ProjectContainer = styled.div`
  position: relative;
`

const Overlay = styled.div`
  position: absolute;
  color: red;
  top: 0px;
  left: 0px;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  transition: 0.3s all ease-in-out;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  &:hover {
    opacity: 1;
  }
  h2,
  span {
    color: var(--main-accent-color);
  }
  h2 {
    font-size: 2rem;
  }
  span {
    font-size: 1.5rem;
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
  span:hover {
    opacity: 1;
  }
`

const MobileDescription = styled.div`
  display: none;
  margin-bottom: 1rem;
  text-align: left;
  @media (pointer: coarse) {
    height: 4ch;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
  }
`

const ProjectLink = ({ project, delay }) => {
  const item = project.node.frontmatter
  const itemAnimations = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: delay / 5,
      },
    },
  }
  return (
    <motion.li variants={itemAnimations}>
      <MobileDescription>
        <Link to={item.path}>
          {item.title} - {item.category}
        </Link>
      </MobileDescription>
      <ProjectContainer>
        <Link to={item.path}>
          <Img
            fluid={item.featuredImage.childImageSharp.fluid}
            alt={item.title}
            style={{ boxShadow: "3px 3px 6px 0px rgba(173, 161, 173, 1)" }}
          />
          <Overlay>
            <h2>{item.title}</h2>
            <span>{item.category}</span>
          </Overlay>
        </Link>
      </ProjectContainer>
    </motion.li>
  )
}

export default ProjectLink
